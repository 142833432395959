<template>
  <bar-chart-instance
    :chart-data.sync="chartData"
    :options.sync="chartOptions"
    :height="280"
  />
</template>

<script>
import BarChartInstance from "./bar-chart";

export default {
  name: "BarChart",
  props: {
    type: {
      type: String,
      default: ""
    },
    labels: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    stacked: {
      type: Boolean,
      default: true
    },
    barThickness: {
      type: Number,
      default: 6
    }
  },
  components: {
    BarChartInstance
  },
  computed: {
    chartData() {
      let data = [];
      switch (this.type) {
        case "single":
          data = [
            {
              label: this.data[0].label,
              backgroundColor: "rgb(255, 157, 0)",
              barThickness: this.barThickness,
              minBarLength: 2,
              data: this.data[0].data
            }
          ];
          break;
        case "fraction":
          data = [
            {
              label: this.data[0].label,
              backgroundColor: "rgb(255, 157, 0)",
              barThickness: this.barThickness,
              minBarLength: 2,
              data: this.data[0].data
            },
            {
              label: this.data[1].label,
              backgroundColor: "rgba(121, 139, 131, 0.1)",
              barThickness: this.barThickness,
              minBarLength: 2,
              data: this.data[1].data.map((x, i) => {
                return Math.abs(x - this.data[0].data[i]);
              })
            }
          ];
          break;
        case "multiple":
          this.data.forEach(week => {
            data.push({
              label: week.label,
              backgroundColor: "rgb(255, 157, 0)",
              barThickness: 20,
              minBarLength: 2,
              data: week.data
            });
          });
          break;
        default:
          data = [
            {
              label: this.data[0].label,
              backgroundColor: "rgb(255, 157, 0)",
              barThickness: this.barThickness,
              minBarLength: 2,
              data: this.data[0].data
            }
          ];
          break;
      }
      return {
        labels: this.labels,
        datasets: data
      };
    },
    setStep() {
      return (
        Math.round(Math.max(...this.chartData.datasets[0].data) / 4 / 10) * 10
      );
    },
    chartOptions() {
      return {
        responsive: true,
        legend: false,
        maintainAspectRatio: false,
        barValueSpacing: 5,
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: false
              },
              stacked: this.stacked
            }
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                display: false
              },
              stacked: this.stacked,
              ticks: {
                beginAtZero: true,
                stepSize: this.setStep
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || "";
              let value;
              if (tooltipItem.datasetIndex === 1 && this.type === "fraction") {
                value =
                  tooltipItem.yLabel + data.datasets[0].data[tooltipItem.index];
              } else {
                value = tooltipItem.value;
              }
              if (label) {
                label += ": ";
              }
              label += value;
              return label;
            }
          }
        },
        layout: {
          padding: {
            top: 10
          }
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped></style>
