<template>
  <div>
    <div class="is-flex is-align-center">
      <eden-page-header title="Feedback" />
      <el-dropdown
        class="dropdown-with-buttons ml-2"
        @command="setPeriod"
        :style="{ marginBottom: '50px' }"
      >
        <el-button size="mini" type="primary" plain>
          {{ periods[period] }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(value, key, i) in periods"
            :key="i"
            :command="key"
            :class="{ selected: period === key }"
            >{{ value }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <span
        v-if="period === 'custom'"
        class="custom-label"
        @click="showCustomDate = true"
      >
        ({{ customDateLabel }})
      </span>
    </div>
    <eden-loader v-if="fetching" />
    <template v-else>
      <el-row type="flex" :gutter="30" class="flex-wrap">
        <el-col :md="14" :lg="14">
          <feedback-summary-chart
            :loading="fetching"
            :period.sync="period"
            :data="data"
          />
        </el-col>
        <el-col :md="10" :lg="10">
          <feedback-type
            :fetching="fetching"
            :count="data[2]"
            :type="'happy'"
            :period="period"
            :custom-date="customDate"
          />
          <feedback-type
            :fetching="fetching"
            :count="data[3]"
            :type="'unhappy'"
            :period="period"
            :custom-date="customDate"
          />
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30" class="flex-wrap">
        <el-col :md="12" :lg="12">
          <eden-overview-card :show-header="false" :style="{ height: 'auto' }">
            <template slot="content">
              <div class="eden-shoutout">
                <template>
                  <p class="font-lg text-bold">Most Happy Customers</p>
                  <div>
                    <template v-if="gardener.gardener">
                      <p>
                        <span class="text-black">{{ gardener.gardener }}</span>
                        had
                        <span class="text-black"
                          >{{ gardener.no_of_happy_customers }}
                        </span>
                        customers who were happy.
                      </p>
                      <img :src="getImage('user.svg')" alt="User" />
                    </template>
                    <p v-else>No data</p>
                  </div>
                </template>
              </div>
            </template>
          </eden-overview-card>
        </el-col>
        <el-col :md="12" :lg="12">
          <eden-overview-card :show-header="false" :style="{ height: 'auto' }">
            <template slot="content">
              <div class="eden-shoutout">
                <template>
                  <p class="font-lg text-bold">Most Satisfying Service</p>
                  <div>
                    <template v-if="service.service">
                      <p>
                        A total of
                        <span class="text-black">{{
                          service.no_of_happy_customers
                        }}</span>
                        customers were satisfied with our
                        <span v-if="service.service" class="text-black">{{
                          service.service.toLowerCase().includes("meal")
                            ? "Food"
                            : service.service
                        }}</span
                        >.
                      </p>
                      <img
                        :src="
                          getImage(
                            `${
                              service.service.toLowerCase().includes('meal')
                                ? 'meal'
                                : service.service.toLowerCase()
                            }.svg`
                          )
                        "
                        alt="Meal"
                      />
                    </template>
                    <p v-else>No data</p>
                  </div>
                </template>
              </div>
            </template>
          </eden-overview-card>
        </el-col>
      </el-row>
    </template>
    <eden-custom-calendar
      :show.sync="showCustomDate"
      title="Feedback summary for"
      @get="getFeedbackSummaryWithCustomDate"
    />
  </div>
</template>

<script>
import FeedbackSummaryChart from "@/components/Feedback/Feedback/FeedbackSummaryChart";

import * as actions from "@/store/action-types";
import feedback from "@/requests/feedback/feedback";
import FeedbackType from "@/components/Feedback/Feedback/FeedbackType";

export default {
  name: "Feedback",
  components: {
    FeedbackType,
    FeedbackSummaryChart,
  },
  data() {
    return {
      fetching: false,
      period: "lastweek",
      periods: {
        last90: "Last 90 days",
        last60: "Last 60 days",
        last30: "Last 30 days",
        lastmonth: "Last month",
        lastweek: "Last week",
        thisweek: "This week",
        thismonth: "This month",
        custom: "Custom",
      },
      data: [],
      gardener: {},
      service: {},
      showCustomDate: false,
      customDate: {},
      customDateLabel: "",
    };
  },
  watch: {
    period() {
      if (this.period !== "custom") {
        this.getFeedbackSummary();
        this.getGardenerWithMostCustomers();
        this.getServiceWithMostCustomers();
      } else {
        this.showCustomDate = true;
      }
    },
  },
  created() {
    this.getFeedbackSummary();
    this.getGardenerWithMostCustomers();
    this.getServiceWithMostCustomers();
  },
  methods: {
    setPeriod(period) {
      this.period = period;
    },
    setChartData() {
      const data = this.$store.getters.feedback_summary[this.period];
      this.data = Object.keys(data).map((key) => data[key]);
    },
    getFeedbackSummary(range) {
      this.fetching = true;
      this.$store
        .dispatch(actions.GET_FEEDBACK_SUMMARY, { period: this.period, range })
        .then(() => {
          this.setChartData();
          this.fetching = false;
        });
    },
    getGardenerWithMostCustomers(range) {
      feedback
        .getGardenerWithMostCustomers(this.period, range)
        .then((response) => {
          this.gardener = response.data.data;
        })
        .catch((error) => {
          this.fetching = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getServiceWithMostCustomers(range) {
      feedback
        .getServiceWithMostCustomers(this.period, range)
        .then((response) => {
          if (response.data.data.service) {
            this.service = response.data.data;
          }
        })
        .catch((error) => {
          this.fetching = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getFeedbackSummaryWithCustomDate(range) {
      const { from, to } = range;
      const payload = {
        from_date: from,
        to_date: to,
      };

      this.customDate = payload;
      this.customDateLabel = `${this.formatDate(
        from,
        "mo d, y"
      )} - ${this.formatDate(to, "mo d, y")}`;

      this.getFeedbackSummary(payload);
      this.getGardenerWithMostCustomers(payload);
      this.getServiceWithMostCustomers(payload);
    },
    seeFeedbackType(type) {
      let query;
      if (this.period === "custom") {
        query = {
          start_date: this.customDate.from_date,
          end_date: this.customDate.to_date,
        };
      } else {
        query = {
          period: this.period,
        };
      }

      this.$router.push({
        name: "feedback.type",
        params: { type },
        query: query,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.eden-feedback {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;

  h1 {
    color: #21312a;
    font-size: 2.5rem;
  }

  p {
    font-size: 1rem;
  }

  img {
    height: 60px;
    width: 60px;
  }
}

.eden-shoutout {
  padding: 30px;

  h5 {
    margin-bottom: 20px;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 1.125rem;
      width: 70%;
      line-height: 1.8;
    }

    img {
      height: 80px;
    }
  }
}

.custom-label {
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 40px;
}
</style>
