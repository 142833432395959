<template>
  <div>
    <eden-overview-card title="Summary" :loading="loading">
      <template slot="content">
        <eden-loader v-if="loading" type="bar-chart" />
        <bar-chart
          v-else
          type="single"
          :labels.sync="labels"
          :data.sync="dataValues"
          :bar-thickness="40"
        />
      </template>
    </eden-overview-card>
  </div>
</template>

<script>
import BarChart from "@/components/Charts/BarChart";

export default {
  name: "FeedbackSummaryChart",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      required: true
    }
  },
  components: {
    BarChart
  },
  data() {
    return {
      form: {
        customPeriod: []
      },
      showCustomDate: false,
      labels: [
        "Customers Served",
        "Feedback",
        "Happy Customers",
        "Unhappy Customers"
      ]
    };
  },
  computed: {
    dataValues() {
      return [
        {
          label: "",
          data: this.data
        }
      ];
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped></style>
