<template>
  <el-row type="flex">
    <el-col :span="24">
      <eden-overview-card :show-header="false">
        <template slot="content">
          <div class="eden-feedback">
            <eden-loader v-if="fetching" type="feedback" />
            <template v-else>
              <div>
                <h1>{{ count }}</h1>
                <p
                  class="text-primary text-cursor"
                  @click="seeFeedbackType(type)"
                >
                  {{ formatText(type) }} Customers
                </p>
              </div>
              <img
                :src="getImage(`${type === 'happy' ? 'good' : 'bad'}.svg`)"
                alt="Good"
              />
            </template>
          </div>
        </template>
      </eden-overview-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "FeedbackType",
  props: {
    fetching: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: ""
    },
    period: {
      type: String,
      default: ""
    },
    customDate: {
      type: Object,
      default() {
        return {};
      }
    },
    service: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  methods: {
    seeFeedbackType(type) {
      let query;
      if (this.period === "custom") {
        query = {
          start_date: this.customDate.from_date,
          end_date: this.customDate.to_date
        };
      } else {
        query = {
          period: this.period
        };
      }

      if (this.service) {
        query.service = this.service;
      }

      this.$router.push({
        name: "feedback.type",
        params: { type },
        query: query
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.eden-feedback {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;

  h1 {
    color: #21312a;
    font-size: 2.5rem;
  }

  p {
    font-size: 1rem;
  }

  img {
    height: 60px;
    width: 60px;
  }
}

.eden-shoutout {
  padding: 30px;

  h5 {
    margin-bottom: 20px;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 1.125rem;
      width: 70%;
      line-height: 1.8;
    }

    img {
      height: 80px;
    }
  }
}
</style>
